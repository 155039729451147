<template>
  <fragment>
    <template v-if="verifying">
      <div class="card card-authentication">
        <div class="card-body">
          <router-link :to="{ name: 'login' }">
            <img class="logo" src="@/assets/img/logo/primary.svg" alt="MedCPD" />
          </router-link>
          <h1>Verifying Email</h1>

          <p class="text-muted text-large mb-0">
            Please wait while we verify your email.
          </p>
        </div>
      </div>
    </template>
    <verification-failed
      v-else-if="verificationFailed"
      :error="verificationFailedError"
    />
    <verification-success v-else-if="verificationSucceeded" />
    <template v-else-if="pendingVerification">
      <div class="card card-authentication">
        <div class="card-body">
          <router-link :to="{ name: 'landing' }">
            <img class="logo" src="@/assets/img/logo/primary.svg" alt="MedCPD" />
          </router-link>

          <h1>Verify Your Email.</h1>

          <p>
            Thank you for registering with ####. We have sent an email to <strong>{{ user.email }}</strong> to verify your account. Please check your inbox and verify you address before continuing.
          </p>

          <p class="text-muted">
            If you don’t see the verification email in your Inbox, check your Junk Folder or request the email to be re-sent by clicking the button below.
          </p>

          <div class="row mt-4">
            <div class="col-auto text-end d-flex align-items-center">
              <router-link class="small text-muted" :to="{ name: 'logout' }">
                Sign Out.
              </router-link>
            </div>
            <div class="col text-end">
              <verification-resend />
            </div>
          </div>
        </div>
      </div>
    </template>
  </fragment>
</template>

<script>
import { authComputed, authMethods } from '@/vuex/helpers'
import { http } from '@/utils'
import VerificationSuccess from './AccountVerificationSuccess'
import VerificationFailed from './AccountVerificationFailed'
import VerificationResend from './AccountVerificationResend'

export default {
  props: {
    signature: String
  },

  components: {
    VerificationSuccess,
    VerificationFailed,
    VerificationResend
  },

  data () {
    return {
      verifying: false,
      verificationFailed: false,
      verificationSucceeded: false,
      verificationFailedError: null
    }
  },

  computed: {
    ...authComputed,

    expires () {
      return this.$route.query.expires
    },

    identifier () {
      return this.$route.query.identifier
    },

    pendingVerification () {
      return this.$user?.new_email || !this.$user?.email_verified
    }
  },

  async created () {
    if (this.user.email_verified && !this.user.new_email) {
      this.verificationSucceeded = true
    } else if (this.signature) {
      this.verifying = true

      const { signature, expires, identifier } = this

      try {
        await http.post('/auth/verification', { signature, expires, identifier }, {
          throwForbiddens: true
        })

        // After verification we need to re-authorize to fetch the fresh user instance.
        await this.authorize()

        this.verificationSucceeded = true
      } catch (error) {
        this.verificationFailed = true
        if (error.response) {
          this.verificationFailedError = error.response.data.message
        }
      } finally {
        this.verifying = false
      }
    } else if (this.pendingVerification === false) {
      this.$router.replace({ name: 'login' })
    }
  },

  methods: authMethods
}
</script>
